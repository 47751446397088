import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/ru';
export const DatepickerRange = ({ onValueChange, initialDate }) => {
    return (
        <div className="datepicker-wrapper">
            <DatePickerInput
                type="range"
                label="Выберите период"
                placeholder="Выберите период"
                value={initialDate}
                onChange={onValueChange}
                locale="ru"
                valueFormat="DD MMM YYYY"
            />
        </div>
    );
};