import { Datepicker } from '../../shared/ui/datepicker/Datepicker';
import {DatepickerRange} from "../../shared/ui/datepicker/DatapickerRange";
import React from 'react';
import { useState, useEffect} from 'react';
import {formatDate, formatFromDate, formatToDate} from '../../shared/lib/hooks/formatDate';
import Header from '../../shared/ui/header/Header';
import {fetchData} from '../../shared/lib/hooks/makeReport';
import {fillTable} from '../../shared/lib/hooks/makeReport';
import { IoIosRemoveCircleOutline } from "react-icons/io";
import {getQueueList} from "../../shared/lib/hooks/groupData";
import { useDisclosure } from '@mantine/hooks';
import {Button, Autocomplete, Select, Checkbox, Box, LoadingOverlay} from "@mantine/core";

export const TimeCalculation = () => {
  const [inputValues, setInputValues] = useState([
    { login: '', tracker: '', id: null, date: null }]);
  const [startDate, setDate] = useState(null);
  const [dates, setDates] = useState([null, null]);
  const [trackers, setTrackers] = useState([]);
  const [recievedData, setRecievedData] = useState([]);
  const [users, setUsers] = useState([]);
  const [queueList, setQueueList] = useState([{ key: '', sumDuration: null }]);
  const [checkboxes, setCheckboxes] = useState({});
  const [filterArr, setFilterArr] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [visible, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    fetch('/api/v1/trackers')
        .then((response) => response.json())
        .then((data) => setTrackers(data.trackers));
  }, []);

  useEffect(() => {
    const storedInputValuesString = window.localStorage.getItem('form');
    const storedInputValues = JSON.parse(storedInputValuesString);
    setInputValues(storedInputValues);
  }, []);

  useEffect(() => {
    const storedInputValuesString = window.localStorage.getItem('form');
    const storedInputValues = JSON.parse(storedInputValuesString);
    if (storedInputValues && storedInputValues.length > 0) {
      const date = new Date(storedInputValues[0].date);
      setDate(date);
    }else {
      const newInputValues = [{ login: '', tracker: '', id: null, date: null }];
      setInputValues(newInputValues);
    }
  }, []);

  useEffect(() => {
    if (filterArr.length === 0 && checkbox === false) {
      setCheckboxes((prevCheckboxes) => {
        const newCheckboxes = { ...prevCheckboxes };

        queueList.forEach((item) => {
          newCheckboxes[item.key] = true;
        });

        return newCheckboxes;
      });
    }
  }, [queueList]);


  const handleButtonClick = async () => {
    if (queueList.length > 0) {
      setCheckbox(false);
      setCheckboxes((prevCheckboxes) => {
        const newCheckboxes = { ...prevCheckboxes };

        queueList.forEach((item) => {
          newCheckboxes[item.key] = true;
        });

        return newCheckboxes;
      });
    }
    await handleCheckboxClick();
  };

  const handleCheckboxClick = async () => {
    setLoading(true);
    open();
    try {
      let dateFrom;
      let dateTo;
      if (dates[0] !== null && dates[1] !== null) {
        dateFrom = formatFromDate(dates[0]);
        dateTo = formatToDate(dates[1]);
      } else {
        dateFrom = formatDate(startDate, false);
        dateTo = formatDate(startDate, true);
      }
      const storedData =
          JSON.parse(window.localStorage.getItem('storedData')) || [];
      let updatedData = [];
      const promises = [];
      for (const item of inputValues) {
        const login = item.login.split(' ')[0];
        if (login.length > 0) {
          const filteredData = storedData.find(
              (data) => data.name === item.tracker,
          );
          const token = filteredData.token;
          const id = filteredData.idOrg;
          const promise = fetchData(
              `/api/v2/worklog?createdBy=${login}&start=from:${dateFrom}&start=to:${dateTo}&perPage=1000`,
              id,
              token,
          );
          promises.push(promise);
        }
      }
      const results = await Promise.all(promises);
      updatedData = results.flat();
      setRecievedData(updatedData);
    } catch {
      console.log('error');
    } finally {
      close();
    }
    const newInputValues = [...inputValues];
    if (newInputValues.length > 0) {
      newInputValues[0].date = startDate;
      setInputValues(newInputValues);
      const inputValuesString = JSON.stringify(inputValues);
      window.localStorage.setItem('form', inputValuesString);
    } else {
      const inputValuesString = JSON.stringify(inputValues);
      window.localStorage.setItem('form', inputValuesString);
    }
  };

  const handleDatepickerValueChange = async (newValue) => {
    setDates(newValue);
  };

  useEffect(() => {
    if (recievedData) {
      fillTable(recievedData, filterArr, checkbox);
      const filteredByQueue = getQueueList(recievedData);
      console.log(filteredByQueue);
      setQueueList(filteredByQueue);
    }
  }, [recievedData]);




  const handleInputChange = async (index, field, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index][field] = value;
    setInputValues(newInputValues);
  };

  const handleAddInput = () => {
    setInputValues((prevInputValues) => {
      const newInput = {
        login: '', tracker: '', id: Date.now(), date: null
      };
      setTimeout(() => {
        const block = document.querySelector(".input-box");
        block.scrollTop = block.scrollHeight;
      }, 250);
      return [...prevInputValues, newInput];
    });
  };

  const handleDeleteInput = (id) => {
    setInputValues((prevInputValues) => {
      const newInputValues = prevInputValues.filter((_, i) => i !== id);
      return newInputValues;
    });
  };

  const handleChange  = async (index, field, val) => {
    if (inputValues[index].tracker !== '') {
      await handleInputChange(index, field, val);
    }
  }

  const handleTrackerChange = async (index, field, val) => {
    const newInputValues = [...inputValues];
    newInputValues[index][field] = val; // Обновление свойства tracker в соответствующем объекте
    setInputValues(newInputValues);
  }

  const handleClick = async (index, field, val) => {
    if (inputValues[index].tracker !== '') {
      const newInputValues = [...inputValues];
      setInputValues(newInputValues);
      const url = `/api/v2/users?&perPage=1000`;
      const storedData = JSON.parse(window.localStorage.getItem('storedData')) || [];
      const filteredData = storedData.find(data => data.name === inputValues[index].tracker);
      if (filteredData) {
        const token = filteredData.token;
        const id = filteredData.idOrg;
        try {
          const data = await fetchData(url, id, token);
          const uniqUsers = Array.from(
              new Map(data.map(user => [user.login, user]))
                  .values()
          );
          setUsers(uniqUsers);
        }
        catch {
          console.log('Fetch error');
        }
      } else {
        console.log('Tracker data not found');
      }
    } else {
      console.log('Empty tracker or input value');
    }
  }

  const handleCheckboxChange = async (value) => {
    setCheckbox(true);
    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = {
        ...prevCheckboxes,
        [value.key]: !prevCheckboxes[value.key],
      };
      const filterArr = Object.keys(newCheckboxes).filter(
          (key) => newCheckboxes[key] && key !== '',
      );
      setFilterArr(filterArr);
      return newCheckboxes;
    });
    await handleCheckboxClick();
  };



  return (
    <div>
      <Header />
      <Box pos="relative">
        <LoadingOverlay
            visible={visible}
            loaderProps={{ children: 'Загрузка...' }}
        />
      <div className="container">
        <Datepicker className = 'datepicker' startDate={startDate} setDate={setDate} defaultValue={startDate} />
        <div className='input-box'>
          {inputValues.map((input, id) => (
              <div className="wrapper" key = {input.id}>
                <div className='input-container'>
                  <div className="input-wrapper">
                    <Select
                        value={input.tracker}
                        data={trackers.map(item=> item.name)}
                        onChange={(val) => handleTrackerChange(id, 'tracker', val)}
                        label="Выберите организацию"
                        placeholder="Организация"
                    />
                  </div>
                  <div className="input-wrapper">
                    <Autocomplete
                        value={inputValues[id].login}
                        data={users.map(item=> `${item.login} ${item.display}` )}
                        onClick={(val)=>handleClick(id, 'login', val)}
                        onChange={(val)=>handleChange(id, 'login', val)}
                        label="Введите логин"
                        placeholder="Логин"
                    />
                  </div>
                  <button className='remove-button' onClick={() => handleDeleteInput(id)}><IoIosRemoveCircleOutline size='28' color='red'/> </button>
                </div>
              </div>
          ))}
        </div>
        <div className="button-wrapper">
          <Button fullWidth size="s" onClick={handleAddInput}>
            Добавить логин
          </Button>
          <Button fullWidth size="s" onClick={handleButtonClick}>
            Отчет
          </Button>
        </div>
      </div>
      </Box>
      <div className="filter-container">
        {queueList.length > 0 && (
            <DatepickerRange
                className="datepicker"
                onValueChange={handleDatepickerValueChange}
                initialDate={dates}
            />
        )}
        {queueList.length > 0 && queueList.map((value, index) => (
            <React.Fragment key={index}>
              <div className="checkbox-wrapper">
                <Checkbox
                    key={index}
                    label={`${value.key} ${value.sumDuration}ч `}
                    checked={!!checkboxes[value.key]}
                    onChange={() => handleCheckboxChange(value)}
                    size="md"
                />
              </div>
              {index < queueList.length - 1 && <span className="plus">+</span>}
            </React.Fragment>
        ))}
        {recievedData.length > 0 && <div className="total-sum"></div>}
      </div>
      <div className="table-container"></div>
    </div>
  );
};
